import moment from 'moment-timezone';

export class DateTimeUtil {
  /**
   * Converts a date string to a specified format.
   * @param date - The date string to be converted.
   * @param format - The desired format for the date.
   * @returns The formatted date string.
   */
  static formatDate(date: string | Date, format: string): string {
    return moment(date).format(format);
  }

  /**
   * Calculates the difference between two dates in the specified unit.
   * @param date1 - The first date.
   * @param date2 - The second date.
   * @param unit - The unit of time to measure the difference (e.g., 'days', 'months', 'years').
   * @returns The difference between the two dates in the specified unit.
   */
  static dateDifference(date1: string | Date, date2: string | Date, unit: moment.unitOfTime.Diff = 'days'): number {
    return moment(date1).diff(moment(date2), unit);
  }

  /**
   * Adds a specified amount of time to a date.
   * @param date - The original date.
   * @param amount - The amount of time to add.
   * @param unit - The unit of time to add (e.g., 'days', 'months', 'years').
   * @returns A new Date object with the added time.
   */
  static addTime(date: string | Date, amount: number, unit: moment.unitOfTime.DurationConstructor): Date {
    return moment(date).add(amount, unit).toDate();
  }

  /**
   * Subtracts a specified amount of time from a date.
   * @param date - The original date.
   * @param amount - The amount of time to subtract.
   * @param unit - The unit of time to subtract (e.g., 'days', 'months', 'years').
   * @returns A new Date object with the subtracted time.
   */
  static subtractTime(date: string | Date, amount: number, unit: moment.unitOfTime.DurationConstructor): Date {
    return moment(date).subtract(amount, unit).toDate();
  }

  /**
   * Checks if a date is valid.
   * @param date - The date string or object to be validated.
   * @returns True if the date is valid, false otherwise.
   */
  static isValidDate(date: string | Date): boolean {
    return moment(date).isValid();
  }

  /**
   * Returns the current date and time as a formatted string.
   * @param format - The desired format for the date and time.
   * @returns The current date and time as a formatted string.
   */
  static getCurrentDateTime(format: string = ''): string {
    return moment().format(format);
  }

  /**
   * Gets the start of a unit of time for a given date.
   * @param date - The original date.
   * @param unit - The unit of time to get the start of (e.g., 'day', 'month', 'year').
   * @returns A new Date object representing the start of the specified unit of time.
   */
  static getStartOf(date: string | Date, unit: moment.unitOfTime.StartOf): Date {
    return moment(date).startOf(unit).toDate();
  }

  /**
   * Gets the end of a unit of time for a given date.
   * @param date - The original date.
   * @param unit - The unit of time to get the end of (e.g., 'day', 'month', 'year').
   * @returns A new Date object representing the end of the specified unit of time.
   */
  static getEndOf(date: string | Date, unit: moment.unitOfTime.StartOf): Date {
    return moment(date).endOf(unit).toDate();
  }

  /**
   * Gets the difference between the current time and a given date in the specified unit.
   * @param date - The date to compare with the current time.
   * @param unit - The unit of time to measure the difference (e.g., 'days', 'hours').
   * @returns The difference between the current time and the given date in the specified unit.
   */
  static getTimeFromNow(date: string | Date, unit: moment.unitOfTime.Diff = 'days'): number {
    return moment().diff(moment(date), unit);
  }

  /**
   * Gets the difference between a given date and the current time in the specified unit.
   * @param date - The date to compare with the current time.
   * @param unit - The unit of time to measure the difference (e.g., 'days', 'hours').
   * @returns The difference between the given date and the current time in the specified unit.
   */
  static getTimeUntilNow(date: string | Date, unit: moment.unitOfTime.Diff = 'days'): number {
    return moment(date).diff(moment(), unit);
  }

  /**
   * Checks if a date is the same as or before another date.
   * @param date1 - The first date.
   * @param date2 - The second date to compare against.
   * @param unit - The unit of time to measure the difference
   * @returns True if date1 is the same as or before date2, false otherwise.
   */
  static isSameOrBefore(date1: string | Date, date2: string | Date, unit: moment.unitOfTime.StartOf = 'date'): boolean {
    return moment(date1).isSameOrBefore(moment(date2), unit);
  }

  /**
   * Checks if a date is the same as or after another date.
   * @param date1 - The first date.
   * @param date2 - The second date to compare against.
   * @param unit - The unit of time to measure the difference
   * @returns True if date1 is the same as or after date2, false otherwise.
   */
  static isSameOrAfter(date1: string | Date, date2: string | Date, unit: moment.unitOfTime.StartOf = 'date'): boolean {
    return moment(date1).isSameOrAfter(moment(date2), unit);
  }

  /**
   * Checks if a date is between two other dates.
   * @param date - The date to check.
   * @param startDate - The start date.
   * @param endDate - The end date.
   * @param inclusivity - Whether the bounds are inclusive ('[)' or '()').
   * @returns True if the date is between startDate and endDate, false otherwise.
   */
  static isBetween(
    date: string | Date,
    startDate: string | Date,
    endDate: string | Date,
    inclusivity: '()' | '[)' | '(]' | '[]' = '[]'
  ): boolean {
    return moment(date).isBetween(moment(startDate), moment(endDate), undefined, inclusivity);
  }

  /**
   * Converts a date to UTC.
   * @param date - The date to convert.
   * @returns A new Date object representing the UTC time.
   */
  static convertToUTC(date: string | Date): Date {
    return moment(date).utc().toDate();
  }

  /**
   * Converts a UTC date to local time.
   * @param date - The UTC date to convert.
   * @returns A new Date object representing the local time.
   */
  static convertFromUTC(date: string | Date): Date {
    return moment.utc(date).local().toDate();
  }
}
