class MediaUtil {
  validateImage(path): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => reject();
      img.src = path;
    });
  }

  /**
   * @desc create a thumbnail url from a video frame at 0:00
   * @param {string} source
   * @param {number} seekTo
   * @return {Promise<string>}
   */
  async createVideoThumbnailUrl(source: string, seekTo: number = 0) {
    const video = document.createElement('video');
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    video.src = source;

    await new Promise<void>(resolve => {
      video.addEventListener('loadedmetadata', () => {
        canvas.height = 400;
        canvas.width = (400 * video.videoWidth) / video.videoHeight;
        video.currentTime = seekTo;
      });
      video.addEventListener('seeked', () => resolve());
    });

    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    return canvas.toDataURL();
  }
}
export const mediaUtil = new MediaUtil();
