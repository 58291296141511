<template>
  <aula-search
    :include-otp-from-guardian-in-sub-group="includeOtpFromGuardianInSubGroup"
    :search-result-loader="searchRecipients"
    :results="searchRecipientsResult"
    :select-all="selectAll"
    :disabled="disabled"
    :allow-create="allowCreate"
    :reset-search-input="resetSearchInput"
    :existing-participants="existingParticipants"
    :enable-relatives-for-students="enableRelativesForStudents"
    :add-existing-participants="addExistingParticipants"
    :placeholder-textkey="placeholderTextkey"
    :invite-group-when-adding-members="inviteGroupWhenAddingMembers"
    :filter-portal-roles-when-adding-members="filterPortalRolesWhenAddingMembers"
    :split-groups-participants="splitGroupsParticipants"
    :disable-sub-group-select="disableSubGroupSelect"
    :close-dropdown-after-select="closeDropdownAfterSelect"
    :invite-members-when-selecting-group="inviteMembersWhenSelectingGroup"
    :invite-members-when-selecting-group-portal-role="inviteMembersWhenSelectingGroupPortalRole"
    :show-members-from-group="showMembersFromGroup"
    :show-groups="showGroups"
    :disable-portal-roles-members-when-expanding-group="disablePortalRolesMembersWhenExpandingGroup"
    :css-class="cssClass"
    :popper-class="popperClass"
    :show-checkbox="showCheckbox"
    :from-module="fromModule"
    :close-search-result="closeSearchResult"
    :include-self="includeSelf"
    :show-search-icon="showSearchIcon"
    :multiple="multiple"
    :multiple-limit="multipleLimit"
    :only-unique-profiles="onlyUniqueProfiles"
    :set-focus="setFocus"
    :instance="instance"
    :selected-profile-ids-for-checkbox="selectedProfileIdsForCheckbox"
    :hover-text="hoverText"
    :active-institution-code-for-group-memberships="activeInstitutionCodeForGroupMemberships"
    :append-to-body="appendToBody"
    style="width: 100%"
    @emitSelected="emitSelected"
    @emitFocus="emitFocus"
  />
</template>

<script>
import Vue from 'vue';
import { types } from '../../src/store/types/types';
import { axios, portal } from '../assets/plugins/axios/axios';
import { mapActions, mapGetters } from 'vuex';
import { institutionTypes } from '../enums/institutionTypes';
import { portalRoles } from '../enums/portalRoles';
import { moduleTypes } from '../enums/moduleTypes';
import { docTypes } from '../enums/docTypes';
import { institutionRole } from '../enums/institutionRole';
import { groupTypes } from '../enums/groupTypes';
import { messageOwnerTypes } from '../enums/messageOwnerTypes';

const axiosCancelToken = axios.CancelToken;

export default {
  name: 'AulaSearchRecipients',
  props: {
    allowAllCommonInbox: { type: Boolean, default: false },
    includeOtpFromGuardianInSubGroup: { type: Boolean, default: false },
    multiple: { type: Boolean, default: true },
    setFocus: { type: Boolean, default: false },
    multipleLimit: { type: Number, default: 0 },
    allowCreate: { type: Boolean, default: false },
    resetInput: { type: Boolean, default: false },
    portalRoles: { type: Array, default: () => [] },
    url: { type: String, default: 'findRecipients' },
    urlPrefix: { type: String, default: 'search' },
    filterOnGroup: { type: Number, default: null },
    selectAll: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    includeChoosenInSearch: { type: Boolean, default: false },
    showSearchIcon: { type: Boolean, default: false },
    enableRelativesForStudents: { type: Boolean, default: false },
    splitGroupsParticipants: { type: Boolean, default: true },
    disableSubGroupSelect: { type: Boolean, default: false },
    existingParticipants: { type: Array, default: () => [] },
    addExistingParticipants: { type: Boolean, default: true },
    scopeEmployeesToInstitution: { type: Boolean, default: true },
    placeholderTextkey: { type: String, default: 'SEARCH_LABEL_CHILD' },
    municipalityInstitutionId: { type: String, default: null },
    inviteGroupWhenAddingMembers: { type: Boolean, default: false },
    closeDropdownAfterSelect: { type: Boolean, default: true },
    inviteMembersWhenSelectingGroup: { type: Boolean, default: false },
    inviteMembersWhenSelectingGroupPortalRole: {
      type: Boolean,
      default: false,
    },
    showMembersFromGroup: { type: Boolean, default: false },
    showGroups: { type: Boolean, default: true },
    disableDoctypes: { type: Array, default: () => [] },
    hidePortalRolesWhenExpandingGroup: { type: Array, default: () => [] },
    disablePortalRolesWhenExpandingGroup: { type: Array, default: () => [] },
    disablePortalRolesMembersWhenExpandingGroup: {
      type: Array,
      default: () => [],
    },
    filterPortalRolesWhenAddingMembers: { type: Array, default: () => [] },
    includeSelf: { type: Boolean, default: false },
    cssClass: { type: String, default: '' },
    fromModule: { type: String, default: null },
    docTypesApi: {
      type: Array,
      default: () => [docTypes.PROFILE, docTypes.GROUP],
    },
    onlyUniqueProfiles: { type: Boolean, default: false },
    institutionCode: { type: String, default: null },
    alternativeGroupSort: { type: Boolean, default: false },
    closeSearchResult: { type: Boolean, default: false },
    extraAPIParams: { type: Object, default: null },
    showCheckbox: { type: Boolean, default: true },
    instance: { type: Number, default: null },
    listMyGroupsOnFocus: { type: Boolean, default: false },
    splitMyGroupsAndOtherGroups: { type: Boolean, default: false },
    popperClass: { type: String, default: 'aula-search-popper' },
    isMultipleInstitutionProfileAllowed: { type: Boolean, default: true },
    selectedProfileIdsForCheckbox: { type: Array, default: () => [] },
    hiddenParticipants: { type: Array, default: () => [] },
    hoverText: { type: String, default: '' },
    activeInstitutionCodeForGroupMemberships: { type: String, default: '' },
    appendToBody: { type: Boolean, default: true }
  },
  data() {
    return {
      searchRecipientsResult: [],
      recipientIds: [],
      recipients: [],
      docTypes: docTypes,
      institutionRole: institutionRole,
      groupTypes: groupTypes,
      institutionTypes: institutionTypes,
      cancelRequestFunc: null,
    };
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      userGroups: types.GET_USER_GROUPS,
      childRelationResults: types.GET_CHILD_RELATION_RESULTS,
      institutions: types.GET_INSTITUTIONS,
      selectedInstitution: types.GET_SELECTED_INSTITUTION,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      hasPermission: types.HAS_PERMISSION,
      calendarActiveInstProfileId: types.GET_CALENDAR_ACTIVE_INST_PROFILE_ID,
      chosenMessageFolder: types.MESSAGES_GET_CHOSEN_FOLDER_AND_MAIL_OWNER,
    }),
    resetSearchInput() {
      return this.resetInput;
    },
    isAdministration() {
      return window.location.href.includes('administration');
    },
    disableGroupSelect() {
      return this.splitGroupsParticipants && !this.isAdministration;
    },
  },
  methods: {
    ...mapActions({
      loadRecipientSearchResults: types.LOAD_MESSAGE_RECIPIENT_SEARCH_GROUPED_RESULTS,
    }),
    async searchRecipients(query) {
      let params = {
        text: query,
        query: query,
        id: this.profile.id,
        typeahead: true,
        limit: this.filterOnGroup ? null : 100,
        scopeEmployeesToInstitution: this.scopeEmployeesToInstitution,
        fromModule: this.fromModule != null ? this.fromModule : null,
      };
      if (this.extraAPIParams != null) {
        params = Object.assign(params, this.extraAPIParams);
      }

      const apiUrl = '?method=' + this.urlPrefix + '.' + this.url;

      if (this.portalRoles.length) {
        params.portalRoles = this.portalRoles;
      }

      if (this.institutionCode != null) {
        params.instCode = this.institutionCode;
      }
      if (this.filterOnGroup != null) {
        params.groupId = this.filterOnGroup;
      }

      if (this.docTypesApi.length) {
        params.docTypes = this.docTypesApi;
      }

      if (this.municipalityInstitutionId != null) {
        params.municipalityInstitutionId = this.municipalityInstitutionId;
      }
      if (query != '') {
        try {
          if (this.cancelRequestFunc) {
            this.cancelRequestFunc();
          }
          portal
            .get(apiUrl, {
              params: params,
              cancelToken: new axiosCancelToken(c => {
                this.cancelRequestFunc = c;
              }),
            })
            .then(
              response => {
                this.cancelRequestFunc = null;
                const groups = [];
                const children = [];
                const guardians = [];
                let employees = [];
                const dayCare = [];
                const school = [];
                const commonInboxes = [];
                const groupHomes = [];

                response.data.data.results = response.data.data.results.filter(participant =>
                  this.hiddenParticipants.every(
                    hiddenParticipant =>
                      hiddenParticipant.id != participant.id ||
                      hiddenParticipant.mailBoxOwnerType !== participant.mailBoxOwnerType
                  )
                );
                if (this.filterOnGroup != null) {
                  const results = response.data.data.results.filter(d => d.portalRole === portalRoles.CHILD);
                  query = query.replace(/\*/g, '');
                  results.forEach(item => {
                    let addItem = false;
                    if (query == '') {
                      addItem = true;
                    } else {
                      const arrQuery = query.split(' ');
                      for (let i = 0; i < arrQuery.length; i++) {
                        if (item.name.toLowerCase().includes(arrQuery[i].toLowerCase())) {
                          addItem = true;
                          break;
                        }
                      }
                    }
                    if (addItem) {
                      const membership = Object.assign({}, item);
                      membership.id = membership.institutionProfileId;
                      membership.docType = docTypes.PROFILE;
                      membership.portalRole = portalRoles.CHILD;
                      this.addSearchItem(
                        membership,
                        groups,
                        children,
                        guardians,
                        employees,
                        dayCare,
                        school,
                        commonInboxes,
                        groupHomes
                      );
                    }
                  });
                } else {
                  const results = response.data.data.results;
                  if (results != null && results.length > 0) {
                    results.forEach(item => {
                      this.addSearchItem(
                        item,
                        groups,
                        children,
                        guardians,
                        employees,
                        dayCare,
                        school,
                        commonInboxes,
                        groupHomes
                      );
                    });
                  }
                }

                // AULA-34533: do not show other institution profiles of a user if one profile is already a recipient
                if (this.isMultipleInstitutionProfileAllowed === false) {
                  const isUniqueParticipant = participant =>
                    this.existingParticipants.find(profile => profile.profileId == participant.profileId) == null;
                  employees = employees.filter(employee => isUniqueParticipant(employee));
                }

                const dayCareGrouping = {
                  label: Vue.filter('fromTextKey')('DAYCARE'),
                  options: dayCare,
                  value: 'daycares',
                };
                const schoolGrouping = {
                  label: Vue.filter('fromTextKey')('SCHOOL'),
                  options: school,
                  value: 'schools',
                };
                const childrenGrouping = {
                  label: Vue.filter('fromTextKey')('PUPILS'),
                  options: children,
                  value: 'children',
                };
                const employeesGrouping = {
                  label: Vue.filter('fromTextKey')('EMPLOYEES'),
                  options: employees,
                  value: 'employees',
                };
                const guardiansGrouping = {
                  label: Vue.filter('fromTextKey')('PARENTS'),
                  options: guardians,
                  value: 'guardians',
                };
                const groupHomesGrouping = {
                  label: Vue.filter('fromTextKey')('GROUP_HOMES'),
                  options: groupHomes,
                  value: 'groupHomes',
                };
                let memberGroupsGrouping = {};
                let otherGroupsGrouping = {};
                let groupsGrouping = {};
                if (this.splitMyGroupsAndOtherGroups) {
                  memberGroupsGrouping = {
                    label: Vue.filter('fromTextKey')('MY_GROUPS'),
                    options: groups.filter(g => g.isGroupMember),
                    value: 'memberGroups',
                  };
                  otherGroupsGrouping = {
                    label: Vue.filter('fromTextKey')('OTHER_GROUPS'),
                    options: groups.filter(g => !g.isGroupMember),
                    value: 'otherGroups',
                  };
                } else {
                  groupsGrouping = {
                    label: Vue.filter('fromTextKey')('GROUP'),
                    options: groups,
                    value: 'groups',
                  };
                }
                const commonInboxesGrouping = {
                  label: Vue.filter('fromTextKey')('MESSAGE_COMMON_INBOX_DESCRIPTION'),
                  options: commonInboxes,
                  value: 'commonInboxes',
                };
                if (this.alternativeGroupSort) {
                  this.searchRecipientsResult = this.splitMyGroupsAndOtherGroups
                    ? [
                        memberGroupsGrouping,
                        otherGroupsGrouping,
                        childrenGrouping,
                        guardiansGrouping,
                        groupHomesGrouping,
                        employeesGrouping,
                        commonInboxesGrouping,
                      ]
                    : [
                        groupsGrouping,
                        childrenGrouping,
                        guardiansGrouping,
                        groupHomesGrouping,
                        employeesGrouping,
                        commonInboxesGrouping,
                      ];
                } else {
                  if (dayCare.length > 0 || school.length > 0) {
                    this.searchRecipientsResult = [dayCareGrouping, schoolGrouping];
                  } else {
                    this.searchRecipientsResult = this.splitMyGroupsAndOtherGroups
                      ? [
                          groupHomesGrouping,
                          memberGroupsGrouping,
                          otherGroupsGrouping,
                          employeesGrouping,
                          childrenGrouping,
                          guardiansGrouping,
                          commonInboxesGrouping,
                        ]
                      : [
                          groupHomesGrouping,
                          groupsGrouping,
                          employeesGrouping,
                          childrenGrouping,
                          guardiansGrouping,
                          commonInboxesGrouping,
                        ];
                  }
                }
              },
              () => {}
            );
        } catch (error) {
          if (!axios.isCancel(error)) {
            this.cancelRequestFunc = null;
          }
        }
      }
    },
    addSearchItem(item, groups, children, guardians, employees, dayCare, school, commonInboxes, groupHomes) {
      const id = parseInt(item.id) || item.docId;
      if (
        this.includeChoosenInSearch ||
        (this.recipientIds.indexOf(item.docType.toLowerCase() + '-' + id) === -1 &&
          this.recipientIds.indexOf(id) === -1) ||
        this.recipients.find(
          rec => rec.id == id && rec.type == docTypes.GROUP.toLowerCase() && rec.portalRole != null
        ) != null
      ) {
        switch (item.docType) {
          case docTypes.PROFILE: {
            if (
              (!this.includeSelf && this.institutions.filter(inst => inst.institutionProfileId == id).length > 0) ||
              (this.fromModule == moduleTypes.EVENT && this.calendarActiveInstProfileId == id)
            ) {
              return;
            }
            const entry = {
              label: Vue.filter('displayProfileNameWithMetadata')(item),
              value: item.docType.toLowerCase() + id + item.institutionCode,
              name: item.name,
              shortName: item.shortName,
              portalRole: item.portalRole,
              id: id,
              type: item.docType.toLowerCase(),
              institutionCode: item.institutionCode,
              institutionRole: item.institutionRole,
              institutionName: item.institutionName,
              municipalityName: item.municipalityName,
              municipalityCode: item.municipalityCode,
              administrativeAuthority: item.administrativeAuthority,
              aulaEmail: item.aulaEmail !== null ? item.aulaEmail : item.email,
              profileId: item.profileId,
              relatedProfiles: item.relatedProfiles,
              relatedGroups: item.relatedGroups,
              communicationBlock: item.communicationBlock,
              metadata: item.metadata,
              groupHomes: item.groupHomes,
            };

            if (item.groupHomes && item.groupHomes.length > 0) {
              // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
              groupHomes = item.groupHomes;
            }

            const portalRole = item.portalRole || item.role;
            switch (portalRole) {
              case portalRoles.CHILD:
                entry.icon = this.enableRelativesForStudents ? 'icon-Aula_elipses get-child-relatives' : '';
                children.push(entry);
                break;
              case portalRoles.EMPLOYEE:
                employees.push(entry);
                break;
              case portalRoles.GUARDIAN:
                guardians.push(entry);
                break;
            }
            break;
          }
          case docTypes.GROUP: {
            const value = item.docType.toLowerCase() + id + item.institutionCode;
            groups.push({
              label: this.getGroupLabel(item),
              value: value,
              name: item.title || item.name,
              id: id,
              portalRole: null,
              type: item.docType.toLowerCase(),
              class: this.splitGroupsParticipants ? 'parent no-checkbox' : 'parent',
              isExpanded: false,
              disabled: this.disableDoctypes.includes(docTypes.GROUP),
              icon: this.splitGroupsParticipants ? 'el-select__caret el-input__icon el-icon-arrow-down' : '',
              institutionCode: item.institutionCode,
              isGroupMember: item.isGroupMember,
              status: item.status,
            });
            if (this.splitGroupsParticipants) {
              if (!this.hidePortalRolesWhenExpandingGroup.includes(portalRoles.GUARDIAN)) {
                groups.push({
                  label: this.getSubGroupLabel(item, portalRoles.GUARDIAN),
                  labelWhenGroupIsChosen: this.getSubGroupLabelWhenChosen(item, portalRoles.GUARDIAN),
                  value: value + 'guardian',
                  name: item.title || item.name,
                  isAllowMembersToBeShown: item.allowMembersToBeShown,
                  id: id,
                  portalRole: portalRoles.GUARDIAN,
                  type: item.docType.toLowerCase(),
                  disabled: this.disablePortalRolesWhenExpandingGroup.includes(portalRoles.GUARDIAN),
                  isHidden: true,
                  isExpanded: false,
                  class: 'child',
                  icon:
                    this.splitGroupsParticipants && item.allowMembersToBeShown
                      ? 'el-select__caret el-input__icon el-icon-arrow-down'
                      : '',
                  institutionCode: item.institutionCode,
                });
              }
              if (!this.hidePortalRolesWhenExpandingGroup.includes(portalRoles.CHILD)) {
                groups.push({
                  label: this.getSubGroupLabel(item, portalRoles.CHILD),
                  labelWhenGroupIsChosen: this.getSubGroupLabelWhenChosen(item, portalRoles.CHILD),
                  value: value + 'child',
                  name: item.title || item.name,
                  isAllowMembersToBeShown: item.allowMembersToBeShown,
                  id: id,
                  portalRole: portalRoles.CHILD,
                  type: item.docType.toLowerCase(),
                  disabled: this.disablePortalRolesWhenExpandingGroup.includes(portalRoles.CHILD),
                  isHidden: true,
                  isExpanded: false,
                  class: 'child',
                  icon:
                    this.splitGroupsParticipants && item.allowMembersToBeShown
                      ? 'el-select__caret el-input__icon el-icon-arrow-down'
                      : '',
                  institutionCode: item.institutionCode,
                });
              }
              if (!this.hidePortalRolesWhenExpandingGroup.includes(portalRoles.EMPLOYEE)) {
                groups.push({
                  label: this.getSubGroupLabel(item, portalRoles.EMPLOYEE),
                  labelWhenGroupIsChosen: this.getSubGroupLabelWhenChosen(item, portalRoles.EMPLOYEE),
                  value: value + 'employee',
                  name: item.title || item.name,
                  isAllowMembersToBeShown: item.allowMembersToBeShown,
                  id: id,
                  portalRole: portalRoles.EMPLOYEE,
                  type: item.docType.toLowerCase(),
                  disabled: this.disablePortalRolesWhenExpandingGroup.includes(portalRoles.EMPLOYEE),
                  isHidden: true,
                  isExpanded: false,
                  class: 'child',
                  icon:
                    this.splitGroupsParticipants && item.allowMembersToBeShown
                      ? 'el-select__caret el-input__icon el-icon-arrow-down'
                      : '',
                  institutionCode: item.institutionCode,
                });
              }
            }

            break;
          }
          case docTypes.COMMON_INBOX: {
            if (
              this.fromModule == moduleTypes.MESSAGES &&
              this.chosenMessageFolder.mailOwnerId == id &&
              this.chosenMessageFolder.mailOwnerType == messageOwnerTypes.COMMON_INBOX &&
              !this.allowAllCommonInbox
            ) {
              return;
            }
            commonInboxes.push({
              label: item.name + (item.institutionName != null ? ' (' + item.institutionName + ')' : ''),
              value: messageOwnerTypes.COMMON_INBOX + id,
              name: item.name,
              role: messageOwnerTypes.COMMON_INBOX,
              id: id,
              type: item.docType.toLowerCase(),
              institutionCode: item.institutionCode,
              aulaEmail: item.aulaEmail,
            });
            break;
          }
          case docTypes.INSTITUTION: {
            const entry = {
              label: item.institutionName,
              value: item.institutionCode,
              name: item.institutionName,
              id: id,
              type: item.institutionType,
              institutionCode: item.institutionCode,
              municipalityCode: item.municipalityCode,
              municipalityName: item.municipalityName,
            };
            switch (item.institutionType) {
              case institutionTypes.DAYCARE: {
                dayCare.push(entry);
                break;
              }
              case institutionTypes.SCHOOL: {
                school.push(entry);
                break;
              }
            }
            break;
          }
        }
      }
    },
    getSubGroupLabel(item, portalRole) {
      let membershipCount = 0;
      if (item.membershipCount) {
        if (portalRole === portalRoles.GUARDIAN) {
          membershipCount = item.membershipCount.guardians;
        } else if (portalRole === portalRoles.CHILD) {
          membershipCount = item.membershipCount.children;
        } else if (portalRole === portalRoles.EMPLOYEE) {
          membershipCount = item.membershipCount.employees;
        }
      }
      return (
        Vue.filter('fromTextKey')('GROUP_LABEL_TYPE_' + portalRole.toUpperCase()) +
        (item.membershipCount
          ? ' - ' +
            membershipCount +
            ' ' +
            Vue.filter('fromTextKey')(
              membershipCount == 1 ? 'GROUP_LABEL_TYPE_MEMBER' : 'GROUP_LABEL_TYPE_MEMBERS'
            ).toLowerCase()
          : '')
      );
    },
    getSubGroupLabelWhenChosen(item, portalRole) {
      return item.name + ' (' + Vue.filter('fromTextKey')('GROUP_LABEL_TYPE_' + portalRole.toUpperCase()) + ')';
    },
    getGroupLabel(item) {
      return (
        (item.title || item.name) +
        (item.institutionName ? ' (' + item.institutionName + ')' : '') +
        (item.membershipCount
          ? ' - ' +
            item.membershipCount.total +
            ' ' +
            Vue.filter('fromTextKey')(
              item.membershipCount.total == 1 ? 'GROUP_LABEL_TYPE_MEMBER' : 'GROUP_LABEL_TYPE_MEMBERS'
            ).toLowerCase()
          : '')
      );
    },
    emitFocus() {
      if (this.listMyGroupsOnFocus && this.recipients.length === 0) {
        let item = {};
        const groups = [];
        const activeInstitutions = this.activeInstitutions ?? [this.selectedInstitution.institutionCode];
        for (const g of this.userGroups) {
          if (activeInstitutions.includes(g.institutionCode)) {
            item = {
              docType: this.docTypes.GROUP,
              docId: g.id,
              name: g.name,
              id: g.id,
              institutionCode: g.institutionCode,
              institutionName: g.institutionName,
            };
            this.addSearchItem(item, groups, [], [], [], [], [], [], []);
          }
        }
        const groupsGrouping = {
          label: Vue.filter('fromTextKey')('GROUP'),
          options: groups,
          value: 'groups',
        };
        this.searchRecipientsResult = [groupsGrouping];
      }
    },
    emitSelected(recipients) {
      if (this.instance !== null) {
        this.recipientIds = recipients.selectedItems.map(rec => rec.type + '-' + rec.id);
        this.recipients = recipients.selectedItems;
      } else {
        this.recipientIds = recipients.map(rec => rec.type + '-' + rec.id);
        this.recipients = recipients;
      }
      this.$emit('emitSelected', recipients);
    },
    initRecipientIds() {
      this.recipientIds = [];
      this.recipients = [];
      if (this.existingParticipants) {
        for (const existing of this.existingParticipants) {
          if (existing.id != null && !this.recipientIds.includes(existing.id)) {
            let type = '';
            if (existing.type != null) {
              type = existing.type;
            } else if (existing.docType != null) {
              type = existing.docType.toLowerCase();
            } else if (existing.mainGroup != null) {
              type = docTypes.GROUP.toLowerCase();
            }
            if (type != '') {
              this.recipientIds.push(type + '-' + existing.id);
            } else {
              this.recipientIds.push(existing.id);
            }
            this.recipients.push(existing);
          }
        }
      }
    },
  },
  watch: {
    existingParticipants() {
      this.initRecipientIds();
    },
  },
  mounted() {
    this.initRecipientIds();
  },
};
</script>

<style scoped></style>
