








import AulaButtons from '../../../shared/components/AulaButtons.vue';
import AulaButton from '../../../shared/components/AulaButton.vue';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import { PropType } from 'vue';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';

export default {
  components: { Icon, AulaButton, AulaButtons },
  props: {
    profile: { type: Object as PropType<InstitutionProfileModel>, required: true },
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
  },
  methods: {
    handleSendMessageClicked() {
      const query = {
        profile: this.profile.id,
      };
      this.$router.push({ name: 'newMessage', query });
    },
  },
};
