<template>
  <div class="media-tag-dropdown">
    <media-tag-handling-search
      :is-mobile="isMobile"
      :existing-tags="existingTags"
      :can-edit-tags="canEditTags"
      :disable-search="disableSearch"
      :institution-code="institutionCode"
      :show-warning="missingTags"
      :show-add-tag-label="showAddTagLabel"
      :is-multiple-media-tagging="isMultipleMediaTagging"
      @cancelTagging="cancelTagging"
      @addTagsToMedia="addTagsToMedia"
    />
    <media-tag-handling-existing-tags
      v-if="showExistingTags"
      :show-user-icon="isMobile"
      :existing-tags="existingTags"
      :show-delete="canEditTags"
      :class="{ 'pr-2': !isMobile }"
      @removeTagFromMedia="removeTagFromMedia"
    />
  </div>
</template>

<script>
import { moduleTypes } from '../../enums/moduleTypes';
import MediaTagHandlingSearch from './MediaTagHandlingSearch.vue';
import MediaTagHandlingExistingTags from './MediaTagHandlingExistingTags.vue';
export default {
  name: 'MediaTagHandling',
  components: {
    MediaTagHandlingExistingTags,
    MediaTagHandlingSearch,
  },
  props: {
    existingTags: { type: Array, default: () => [] },
    canEditTags: { type: Boolean, default: false },
    disableSearch: { type: Boolean, default: false },
    albumInstitutionCode: { type: String, default: null },
    isMobile: { type: Boolean, default: false },
    institutionCode: { type: String, default: null },
    missingTags: { type: Boolean, default: false },
    showAddTagLabel: { type: Boolean, default: true },
    showExistingTags: { type: Boolean, default: true },
    isMultipleMediaTagging: { type: Boolean, default: false },
  },
  data: function () {
    return {
      moduleTypes,
    };
  },
  methods: {
    removeTagFromMedia(tag) {
      this.$emit('removeTagFromMedia', tag);
    },
    addTagsToMedia(value) {
      this.$emit('addTagsToMedia', value);
    },
    cancelTagging() {
      this.$emit('cancelTagging');
    },
  },
};
</script>

<style scoped lang="scss"></style>
