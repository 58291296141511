import { HttpError } from '../../../src/business/httpError';
import { AxiosError } from 'axios';
import { portal } from '../../assets/plugins/axios/axios';
import { UpdateProfileMasterDataRequest } from '../../models/requests/updateProfileMasterData.request';
import { UpdateProfilePictureRequest } from '../../models/requests/updateProfilePicture.request';
import { ProfileDetailedModel } from '../../models/profileDetailed.model';

class ProfileService {
  public getProfileMasterData = async (institutionProfileIds: number[]): Promise<ProfileDetailedModel> => {
    try {
      const { data } = await portal.get('?method=profiles.getProfileMasterData', {
        params: { instProfileIds: institutionProfileIds },
      });
      return data.data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };
  public updateProfileMasterData = async (payload: UpdateProfileMasterDataRequest) => {
    try {
      await portal.post('?method=profiles.updateProfileMasterData', payload);
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };
  public updateProfilePicture = async (payload: UpdateProfilePictureRequest) => {
    try {
      await portal.post('?method=profiles.updateProfilePicture', payload);
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };
}

export const profileService = new ProfileService();
