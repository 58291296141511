<template>
  <div v-if="canEditTags">
    <mobile-topbar
      v-if="isMobile"
      text-key="GALLERY_TAGS"
      right-text="SUBMIT"
      @leftIconClicked="cancelTagging"
      @rightTextClicked="addTagsToMedia"
    />
    <div class="tag-details mt-1">
      <label v-if="showAddTagLabel" class="d-block font-weight-bold">{{ 'GALLERY_ADD_TAGS' | fromTextKey }}</label>
      <b-input-group class="search-module">
        <aula-search-recipients
          :from-module="moduleTypes.GALLERY"
          :select-all="false"
          :include-self="true"
          :enable-relatives-for-students="false"
          :allow-create="false"
          :close-dropdown-after-select="false"
          :reset-input="resetInput"
          :institution-code="institutionCode"
          :include-choosen-in-search="isMultipleMediaTagging"
          :css-class="showWarning ? 'red-border' : ''"
          :append-to-body="false"
          invite-group-when-adding-members
          invite-members-when-selecting-group-portal-role
          popper-class="media-tag-handling-dropdown"
          :disabled="disableSearch"
          placeholder-textkey="GALLERY_SEARCH_FOR_PROFILES_AND_GROUPS"
          :existing-participants="selectedViewRecipients"
          :hidden-participants="excludeParticipants"
          @emitSelected="emitSelected"
        />
        <b-input-group-addon>
          <b-btn
            v-show="!isEmpty"
            variant="link"
            :aria-label="'ARIA_LABEL_CLEAR_SEARCH' | fromTextKey"
            @click="resetSearchInput"
          >
            <i class="icon icon-Aula_close" />
            <span class="sr-only">{{ 'ARIA_LABEL_DELETE' | fromTextKey }}</span>
          </b-btn>
          <i class="icon-tag" style="font-size: 1.5em" />
        </b-input-group-addon>
      </b-input-group>
    </div>
    <AulaButtons v-if="!isMobile" class="flex-row-reverse">
      <AulaButton variant="primary" :disabled="isEmpty" class="text-uppercase m-3" @click="addTagsToMedia">
        {{ 'SUBMIT' | fromTextKey }}
      </AulaButton>
      <AulaButton variant="link" @click="cancelTagging">
        {{ 'CANCEL' | fromTextKey }}
      </AulaButton>
    </AulaButtons>
  </div>
</template>

<script>
import AulaSearchRecipients from '../../components/AulaSearchRecipients';
import { moduleTypes } from '../../enums/moduleTypes';
import AulaButtons from '../../components/AulaButtons';
import AulaButton from '../../components/AulaButton';
import Button from '../../directives/button';
import MobileTopbar from '../../components/MobileTopbar';
import { userTypes } from '../../enums/userTypes.enum';

export default {
  name: 'MediaTagHandlingSearch',
  components: { MobileTopbar, AulaButton, AulaButtons, AulaSearchRecipients },
  directives: {
    Button,
  },
  props: {
    existingTags: { type: Array, default: () => [] },
    canEditTags: { type: Boolean, default: false },
    disableSearch: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false },
    institutionCode: { type: String, default: null },
    showWarning: { type: Boolean, default: false },
    showAddTagLabel: { type: Boolean, default: true },
    isMultipleMediaTagging: { type: Boolean, default: false },
  },
  data: function () {
    return {
      moduleTypes,
      selectedRecipients: [],
      selectedViewRecipients: [],
      resetInput: false,
    };
  },
  computed: {
    isEmpty() {
      return this.selectedRecipients.length === 0;
    },
    excludeParticipants() {
      return [...this.existingTags, ...this.selectedRecipients];
    },
  },
  methods: {
    addTagsToMedia() {
      this.$emit('addTagsToMedia', this.selectedRecipients);
      this.resetSearchInput();
    },
    emitSelected(recipients) {
      this.selectedRecipients = recipients.filter(recipient => recipient.type === userTypes.PROFILE);
    },
    cancelTagging() {
      this.$emit('cancelTagging');
      this.resetSearchInput();
    },
    resetSearchInput() {
      this.selectedRecipients = [];
      this.selectedViewRecipients = [];
      this.resetInput = !this.resetInput;
    },
  },
};
</script>

<style scoped lang="scss">
.icon-Aula_close {
  font-size: 1rem;
  margin-top: 5px;
  margin-right: 10px;
  cursor: pointer;
}
.search-module {
  --select-input-tags-padding-right: 40px;
}
</style>
