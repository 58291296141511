<template>
  <nav class="toolbar">
    <slot name="start" />
    <div class="primary-content">
      <slot />
    </div>
    <slot name="end" />
  </nav>
</template>
<script lang="js">
export default {
  name: 'AulaNavigationToolbar',
}
</script>
<style lang="scss" scoped>
.toolbar {
  display: flex;
  flex-wrap: wrap;
}

.primary-content {
  flex-grow: 1;
}
</style>
