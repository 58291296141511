<template>
  <div>
    <label class="d-block font-weight-bold">{{ 'GALLERY_TAGS' | fromTextKey }}</label>
    <div class="tag-details scrollbar">
      <div v-for="(tag, i) in existingTags" :key="i" class="existing-tag">
        <Avatar v-if="showUserIcon" :profile="tag" />
        <div :id="'taggedUser' + tag.id" class="tag-name">
          {{ tag | displayProfileNameWithMetadata }}
        </div>
        <b-btn
          v-show="showDelete"
          variant="link"
          type="button"
          :aria-describedby="'taggedUser' + tag.id"
          @click="removeTagFromMedia(tag)"
        >
          <i class="icon icon-Aula_close" />
          <span class="sr-only">{{ 'ARIA_LABEL_DELETE' | fromTextKey }}</span>
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '../../components/Avatar.vue';

export default {
  name: 'MediaTagHandlingExistingTags',
  components: { Avatar },
  props: {
    existingTags: { type: Array, default: () => [] },
    showDelete: { type: Boolean, default: false },
    showUserIcon: { type: Boolean, default: false },
  },
  methods: {
    removeTagFromMedia(tag) {
      this.$emit('removeTagFromMedia', tag);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/breakpoints.scss';
.tag-details {
  @include breakpoint-lg() {
    max-height: var(--tag-details-max-height, calc(50vh - 200px));
    overflow-y: var(--tag-details-tags-overflow-y, auto);
  }
}
</style>
