












import Icon from '../../components/Icon.vue';
import { iconClassEnum } from '../../enums/iconClassEnum';
import { mediaUtil } from '../../utils/mediaUtil';
import { mapActions } from 'vuex';
import { types } from '../../../src/store/types/types.js';
import { PropType } from 'vue';
import { MediaModel } from '../../models/media.model';
import AulaImg from '../../components/AulaImg.vue';
import MediaConversionThumbnail from '../../components/MediaConversionThumbnail.vue';
import { conversionStatusTypes } from '../../enums/conversionStatusTypes';

export default {
  components: { MediaConversionThumbnail, AulaImg, Icon },
  props: {
    media: { type: Object as PropType<MediaModel>, required: true },
  },
  data() {
    return {
      thumbnailUrl: '',
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    isConversing() {
      return this.media.conversionStatus === conversionStatusTypes.PROCESSING;
    },
  },
  mounted() {
    this.createThumbnail();
  },
  methods: {
    ...mapActions({
      loadVideoFromSource: types.VC_LOAD_VIDEO_FROM_SOURCE,
    }),
    async createThumbnail() {
      let videoSource = this.media.file.url;
      if (!(this.media.file instanceof File)) {
        videoSource = await this.loadVideoFromSource(this.media.file);
      }
      this.thumbnailUrl = await mediaUtil.createVideoThumbnailUrl(videoSource);
    },
  },
};
