


























import Icon from './Icon.vue';
import { iconClassEnum } from '../enums/iconClassEnum';
import button from '../../shared/directives/button.js';

export default {
  components: { Icon },
  directives: {
    button,
  },
  props: {
    type: { type: String, default: 'information', validate: value => ['information', 'alert'].includes(value) },
    showDelete: { type: Boolean, default: false },
  },
  emits: ['open', 'delete'],
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
  },
  methods: {
    emitOpen() {
      this.$emit('open');
    },
    emitDelete() {
      this.$emit('delete');
    },
  },
};
