



















import Avatar from '../../../shared/components/Avatar.vue';
import button from '../../../shared/directives/button.js';
import uniqueId from 'lodash/uniqueId';
import ContactPopover from './ContactPopover.vue';
import { PropType } from 'vue';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';

export default {
  components: { ContactPopover, Avatar },
  directives: { button },
  props: {
    profile: { type: Object as PropType<InstitutionProfileModel>, default: null },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    id() {
      return `avatar-${uniqueId()}`;
    },
    canViewContact() {
      return !this.disabled && this.profile;
    },
  },
  methods: {
    handleProfileClicked() {
      if (this.disabled) {
        return;
      }
      this.$router.push({ name: 'userview', params: { id: this.profile.id } });
    },
  },
};
