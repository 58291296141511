<template>
  <div class="buttons">
    <slot />
  </div>
</template>
<script lang="js">
export default {
  name: 'AulaButtons',
}
</script>
<style lang="scss" scoped>
.buttons {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
</style>
