


































import { profileService } from '../../../shared/services/api/profile.service';
import { ProfileDetailedModel } from '../../../shared/models/profileDetailed.model';
import Avatar from '../../../shared/components/Avatar.vue';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import { errorSubCodeTypes } from '../../../shared/enums/errorSubCodeTypes.js';
import { portalRoles } from '../../../shared/enums/portalRoles';
import ContactInformationProfileActions from './ContactInformationProfileActions.vue';
import { permissionEnum } from '../../../shared/enums/permissionEnum';
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types.js';
import button from '../../../shared/directives/button.js';

export default {
  components: { ContactInformationProfileActions, Avatar },
  directives: { button },
  props: {
    target: { type: String, required: true },
    profileId: { type: Number, required: true },
  },
  emits: ['profileClicked'],
  data() {
    return {
      isLoading: false,
      isDeactivated: false,
      isForbidden: false,
      profileMasterData: null as ProfileDetailedModel,
      institutionProfile: null as InstitutionProfileModel,
    };
  },
  computed: {
    ...mapGetters({
      loggedInProfile: types.GET_CURRENT_PROFILE,
      hasPermission: types.HAS_PERMISSION,
    }),
    iconClassEnum() {
      return iconClassEnum;
    },
    canViewProfile() {
      return !this.isForbidden && !this.isDeactivated;
    },
    metadata() {
      if (this.institutionProfile.role !== portalRoles.GUARDIAN) {
        return this.institutionProfile.metadata;
      }
      return null;
    },
    delay() {
      return {
        show: 300,
        hide: 200,
      };
    },
    relations() {
      if (!this.institutionProfile || this.institutionProfile.role !== portalRoles.GUARDIAN) {
        return [];
      }
      return this.institutionProfile.relations;
    },
    isCommunicationBlocked() {
      return !this.hasPermission(permissionEnum.WRITE_MESSAGE) || this.institutionProfile?.communicationBlocked;
    },
    hasBlockedCommunicationChannels() {
      return !!this.institutionProfile?.hasBlockedCommunicationChannels;
    },
    canPerformActions() {
      if (!this.institutionProfile) {
        return false;
      }
      const isLoggedInProfile = this.institutionProfile.profileId === this.loggedInProfile.profileId;
      return !isLoggedInProfile && !this.isCommunicationBlocked && !this.hasBlockedCommunicationChannels;
    },
  },
  methods: {
    show() {
      this.getProfileMasterData();
    },
    async getProfileMasterData() {
      this.isLoading = true;
      try {
        this.profileMasterData = await profileService.getProfileMasterData([this.profileId]);
        this.institutionProfile = this.profileMasterData.institutionProfiles[0];
      } catch (error) {
        if (error.data.status.subCode === errorSubCodeTypes.deactivatedInstitutionProfile) {
          this.isDeactivated = true;
        }
        if (error.data.status.subCode === errorSubCodeTypes.accessDeniedProfileMasterData) {
          this.isForbidden = true;
        }
      }
      this.isLoading = false;
    },
    emitProfileClicked() {
      this.$emit('profileClicked');
    },
  },
};
