import { AxiosError } from 'axios';

export class HttpError {
  data: any;
  message: string;

  constructor(error: AxiosError) {
    this.data = error.response.data;
    this.message = error.message;
  }
}
