










import Icon from '../../components/Icon.vue';
import { iconClassEnum } from '../../enums/iconClassEnum';

export default {
  components: { Icon },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
  },
};
