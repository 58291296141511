import { groupTypes } from '../enums/groupTypes';
import { institutionTypes } from '../enums/institutionTypes';

/**
 * Returns default institution
 * @param {Object[]} institutions
 * @param {String[]} activeInstitutionCodes
 * @param {Object|null} fallbackValue
 * @returns {Object|null}
 */
export const getDefaultInstitution = (institutions, activeInstitutionCodes, fallbackValue = null) => {
  const defaultInstitution = institutions.find(inst => activeInstitutionCodes.includes(inst.institutionCode));
  if (defaultInstitution) {
    return defaultInstitution;
  }
  return fallbackValue;
};

/**
 * Returns default institution code
 * @param {Object[]} institutions
 * @param {String[]} activeInstitutionCodes
 * @param {String|null} fallbackValue
 * @returns {String|null}
 */
export const getDefaultInstitutionCode = (institutions, activeInstitutionCodes, fallbackValue = null) => {
  const defaultInstitutionCode = getDefaultInstitution(institutions, activeInstitutionCodes)?.institutionCode;
  if (defaultInstitutionCode) {
    return defaultInstitutionCode;
  }
  return fallbackValue;
};

/**
 * Returns a list of institution codes filtered by group memberships
 * @param institutions
 * @param group
 * @returns {String[]} */
export const filterGroupMembershipInstitutions = (institutions, group) => {
  if (group.type === groupTypes.MUNICIPAL) {
    return institutions.filter(inst => group.membershipInstitutions.includes(inst.institutionCode));
  }

  if (group.type === groupTypes.CROSS_INSTITUTIONAL) {
    return institutions.filter(inst => inst.groups.some(item => item.id === group.id));
  }

  return institutions.filter(institution => institution.institutionCode === group.institutionCode);
};

/**
 * Returns whether the selected institutionType is Institutional/daycare/school, opposed to municipal/central administration.
 * @param institution
 * @returns {boolean}
 */
export const getIsInstitution = institution =>
  [institutionTypes.INSTITUTION, institutionTypes.DAYCARE, institutionTypes.SCHOOL].includes(
    institution.institutionType
  );

export const getMunicipalInstitution = institutions =>
  institutions.find(institution => institution.institutionType === institutionTypes.MUNICIPALITY);
